import React, { useEffect, useState } from 'react';
import getListAchPayments from '../../../api/services/paymentRequest/getListAchPayments';
import getListPayeesName from '../../../api/services/paymentRequest/getListPayeesName';
import useStatesGlobalStore from '../../../hooks/useStatesGlobalStore';
import usePostPaymentPayeeAch from '../../../api/services/paymentRequest/postPaymentPayeesAch';
import usePutPaymentPayeeAch from '../../../api/services/paymentRequest/putPaymentPayeesAch';
import getPaymentRequest from '../../../api/services/paymentRequest/index';
import usePriceFormat from '../../../hooks/usePriceFormat';
import { useQueryClient } from 'react-query';

const ModalChecksRequests = ({
  setViewPopover,
  setShowAlert,
  isUpdate,
  item,
  isMobile,
}: any) => {
  const { dashboardInfo } = useStatesGlobalStore();
  const [infoPayments, setInfoPayments] = useState<any>({
    type_id: 1,
    payee_name_id: '',
    bank_account_id: '',
    value: Number(
      dashboardInfo.info.total_pending_payment -
        dashboardInfo.info.total_pending_requested,
    ).toFixed(2),
  });

  const { response: listAchpayments } = getListAchPayments();
  const { response: listPayeesName } = getListPayeesName();

  const queryClient = useQueryClient();

  const { mutate,isLoading } = usePostPaymentPayeeAch();
  const { mutate: mutateUpdate, isLoading: loadingUpdate } = usePutPaymentPayeeAch();

  const { refetch } = getPaymentRequest({
    payee_name: '',
  });

  useEffect(() => {
    if (isUpdate) {
      setInfoPayments({
        type_id: isUpdate ? (item?.type?.name === 'Check' ? 1 : 2) : 1,
        payee_name_id: isUpdate
          ? item.payee_name !== null
            ? item.payee_name.id
            : ''
          : '',
        bank_account_id: isUpdate
          ? item.payee_name === null
            ? item.bank_account_id
            : ''
          : '',
        value: isUpdate
          ? item.value
          : dashboardInfo.info.total_pending_payment -
              dashboardInfo.info.total_pending_requested || 0,
      });
    }
  }, [item, isUpdate]);

  const handleSubmit = () => {
    if (
      (infoPayments.payee_name_id === '' &&
        infoPayments.bank_account_id === '') ||
      infoPayments.value === ''
    ) {
      setShowAlert({
        show: true,
        message: 'Please fill all fields',
        title: 'Warning',
        type: 'warning',
      });
      return;
    } else if (
      infoPayments.value >
        parseFloat(
          Number(
            dashboardInfo.info.total_pending_payment -
              dashboardInfo.info.total_pending_requested,
          ).toFixed(2),
        ) ||
      infoPayments.value < 25
    ) {
      setShowAlert({
        show: true,
        message: `The minimum value is $25, The maximum value is ${usePriceFormat(
          dashboardInfo.info.total_pending_payment -
            dashboardInfo.info.total_pending_requested,
        )}`,
        title: 'Warning',
        type: 'warning',
      });
      return;
    } else {
      if (!isUpdate) {
        mutate(
          {
            ...infoPayments,
          },
          {
            onSuccess: (data: any) => {
              if (data?.status) {
                setInfoPayments({
                  type_id: 1,
                  payee_name_id: '',
                  bank_account_id: '',
                  value: Number(
                    dashboardInfo.info.total_pending_payment -
                      dashboardInfo.info.total_pending_requested,
                  ).toFixed(2),
                });

                setShowAlert({
                  show: true,
                  message: `The payment request has been created successfully.`,
                  title: 'Success',
                  type: 'success',
                });
                setViewPopover(false);
                refetch();
                queryClient.invalidateQueries('CARDS');
                queryClient.invalidateQueries('PAYMENTREQUEST');
              }
              if (data?.error) {
                setShowAlert({
                  show: true,
                  message: data.error,
                  title: 'Warning',
                  type: 'warning',
                });
              }
            },
          },
        );
        return;
      } else {
        const code = decodeURIComponent(item.id);

        mutateUpdate(
          {
            infoPayments,
            id: encodeURIComponent(code),
          },
          {
            onSuccess: (data: any) => {
              if (data?.status) {
                setInfoPayments({
                  type_id: 1,
                  payee_name_id: '',
                  bank_account_id: '',
                  value: Number(
                    dashboardInfo.info.total_pending_payment -
                      dashboardInfo.info.total_pending_requested,
                  ).toFixed(2),
                });

                setShowAlert({
                  show: true,
                  message: `The payment request has been updated successfully.`,
                  title: 'Success',
                  type: 'success',
                });
                setViewPopover(false);
                refetch();
                queryClient.invalidateQueries('CARDS');
                queryClient.invalidateQueries('PAYMENTREQUEST');
              }
            },
          },
        );
      }
    }
  };

  const handleCancel = () => {
    setViewPopover(false);

    setInfoPayments({
      type_id: 1,
      payee_name_id: '',
      bank_account_id: '',
      value: Number(
        dashboardInfo.info.total_pending_payment -
          dashboardInfo.info.total_pending_requested,
      ).toFixed(2),
    });
  };

  return (
    <>
      <div className={isMobile ? 'w-80' : 'w-60'} data-testid='popUpdate'>
        {isMobile && <h3 className='text-2xl mb-10'>Request Payments</h3>}
        <p className='font-semibold'>Select payment type</p>
        <div>
          <div>
            <input
              type='radio'
              value={1}
              checked={infoPayments.type_id === 1}
              onChange={(e) =>
                setInfoPayments({
                  ...infoPayments,
                  type_id: Number(e.target.value),
                  payee_name_id: '',
                  bank_account_id: '',
                })
              }
            />
            <label className='ml-3'>Check</label>
          </div>
          <div className='mt-2'>
            <input
              type='radio'
              value={2}
              checked={infoPayments.type_id === 2}
              onChange={(e) =>
                setInfoPayments({
                  ...infoPayments,
                  type_id: Number(e.target.value),
                  payee_name_id: '',
                  bank_account_id: '',
                })
              }
            />
            <label className='ml-3'>ACH Account</label>
          </div>
        </div>
        <hr className='border-t-2 border-gray-300 px-2 my-5' />
        <div className='mt-6'>
          <p>Select an account</p>
          <select
            data-testid='select-account'
            className={`${
              isMobile ? 'w-72' : 'w-44'
            } border-black border-2 p-2 rounded bg-white`}
            value={
              infoPayments.type_id === 1
                ? infoPayments.payee_name_id
                : infoPayments.bank_account_id
            }
            onChange={(e) =>
              infoPayments.type_id === 1
                ? setInfoPayments({
                    ...infoPayments,
                    payee_name_id: e.target.value,
                  })
                : setInfoPayments({
                    ...infoPayments,
                    bank_account_id: e.target.value,
                  })
            }>
            {infoPayments.type_id === 1 ? (
              <>
                <option value=''>Select Payees Name</option>
                {listPayeesName?.data.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </>
            ) : (
              <>
                <option value=''>Select ACH Account</option>
                {listAchpayments?.data.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.account_name}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        <hr className='border-t-2 border-gray-300 px-2 my-5' />
        <div className='mt-6'>
          <p className='font-semibold'>Enter payment value</p>
          <h6>
            Maximum request:
            {usePriceFormat(
              dashboardInfo.info.total_pending_payment -
                dashboardInfo.info.total_pending_requested || 0,
            )}
            ,
          </h6>
          <h6>Minimum request: $25.00</h6>
        </div>
        <div className='relative'>
          <input
            placeholder={usePriceFormat(
              dashboardInfo.info.total_pending_payment -
                dashboardInfo.info.total_pending_requested,
            )}
            // disabled={isUpdate}
            data-testid='input-value'
            defaultValue={isUpdate && infoPayments?.value}
            value={infoPayments.value}
            type='number'
            name='value'
            className={`p-2 ${
              isMobile ? 'w-72' : 'w-40'
            } lg:w-40 text-sm bg-gray-200 rounded-md focus:outline-none focus:bg-white focus:text-gray-900`}
            onChange={(e) =>
              setInfoPayments({
                ...infoPayments,
                value: e.target.value,
              })
            }
          />
          <div className={`${isMobile ? 'hidden' : 'flex'} gap-4 mt-6`}>
            <button
              className='text-blue-400 underline'
              onClick={handleSubmit}
              data-testid='submitCheckRequest'
              disabled={isLoading || loadingUpdate}>
              {isLoading || loadingUpdate ? 'Loading' : 'Submit'}
            </button>
            <button
              className='text-gray-400 underline'
              data-testid={`cancelBtn#${item?.id}`}
              onClick={handleCancel}>
              Cancel
            </button>
          </div>

          <div
            className={` mt-12 ${
              isMobile ? 'flex' : 'hidden'
            } justify-center gap-7`}>
            <button
              className='border-blue-400 border-[1px] text-blue-400 px-12 py-2 rounded-xl hover:bg-blue-400 hover:text-white'
              data-testid={`cancelBtn#${item?.id}`}
              onClick={handleCancel}>
              Cancel
            </button>
            <button
              className='bg-blue-400 text-white font-bold py-2 px-12 rounded-xl focus:outline-none focus:shadow-outline'
              onClick={handleSubmit}
              data-testid='submitACHRequest'
              disabled={isLoading || loadingUpdate}>
              {isLoading || loadingUpdate ? 'Loading' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalChecksRequests;
